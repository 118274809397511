import { FCWithChildren } from '~/shared/types/FCWithChildren';

import { Container, ContentWrapper } from './styles';

import { Toolbar } from './Toolbar';

export const DefaultLayout: FCWithChildren = ({ children }) => {
  return (
    <Container>
      <Toolbar />

      <ContentWrapper>
        <main id="scroll-view">{children}</main>
      </ContentWrapper>
    </Container>
  );
};
