import { FC, Fragment } from 'react';

import { routes } from '~/shared/constants/routes';

import { Container } from './styles';

import { NavMenu } from './NavMenu';
import { INavItemProps, NavItem } from './NavItem';

const navItems: INavItemProps[] = [
  {
    label: 'Resumo',
    href: routes.DASHBOARD,
  },
  {
    label: 'Transações',
    href: routes.PAYMENTS.LIST(),
  },
  {
    label: 'Clientes',
    href: routes.CUSTOMERS.LIST,
  },
  {
    label: 'Formas de pagamento',
    href: routes.PAYMENT_METHODS,
  },
  {
    label: 'Serviços',
    menu: [
      {
        label: 'Gestão de cobranças',
        href: routes.INVOICES.LIST,
      },
      {
        label: 'Links de pagamento',
        href: routes.PAYMENT_LINKS.LIST,
      },
      {
        label: 'DIRF/EFD-REINF',
        href: routes.DOCUMENTS.LIST,
      },
    ],
  },
];

export const SideBarNav: FC = () => {
  return (
    <Container>
      <ul>
        {navItems.map((item) => (
          <Fragment key={`${String(item.href)}-${item.label}`}>
            {item.menu?.length > 0 ? (
              <NavMenu {...item} />
            ) : (
              <NavItem {...item} />
            )}
          </Fragment>
        ))}
      </ul>
    </Container>
  );
};
