import { IResale } from '~/shared/interfaces/IResale';

import { api } from '.';

export async function loadResaleByDomain(domain: string): Promise<IResale> {
  const response = await api.get<IResale>('/resales/domains', {
    params: { domain },
  });

  return response.data;
}
