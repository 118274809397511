/* eslint-disable func-names */
import * as yup from 'yup';

import { validatePhoneNumber } from './validatePhoneNumber';
import { validateNaturalDocument } from './validateNaturalDocument';
import { validateLegalDocument } from './validateLegalDocument';
import { validateIdentityDocument } from './validateIdentityDocument';
import { validateDocument } from './validateDocument';

yup.addMethod(yup.string, 'postalCode', function (errorMessage) {
  return this.test('test-postal-code-valid', errorMessage, function (value) {
    if (!value) return true;

    const { path, createError } = this;

    return value.length === 10 || createError({ path, message: errorMessage });
  });
});

yup.addMethod(yup.string, 'phoneNumber', function (errorMessage) {
  return this.test('test-phone-number-valid', errorMessage, function (value) {
    if (!value) return true;

    const { path, createError } = this;

    return (
      validatePhoneNumber(value) || createError({ path, message: errorMessage })
    );
  });
});

yup.addMethod(yup.string, 'identityDocument', function (errorMessage) {
  return this.test(
    'test-identity-document-valid',
    errorMessage,
    function (value) {
      if (!value) return true;

      const { path, createError } = this;

      return (
        validateIdentityDocument(value) ||
        createError({ path, message: errorMessage })
      );
    }
  );
});

yup.addMethod(yup.string, 'naturalDocument', function (errorMessage) {
  return this.test('test-document-valid', errorMessage, function (value) {
    if (!value) return true;

    const { path, createError } = this;

    return (
      validateNaturalDocument(value) ||
      createError({ path, message: errorMessage })
    );
  });
});

yup.addMethod(yup.string, 'legalDocument', function (errorMessage) {
  return this.test('test-document-valid', errorMessage, function (value) {
    if (!value) return true;

    const { path, createError } = this;

    return (
      validateLegalDocument(value) ||
      createError({ path, message: errorMessage })
    );
  });
});

yup.addMethod(yup.string, 'document', function (errorMessage) {
  return this.test('test-document-valid', errorMessage, function (value) {
    if (!value) return true;

    const { path, createError } = this;

    return (
      validateDocument(value) || createError({ path, message: errorMessage })
    );
  });
});
