import { createContext, useContext, useMemo } from 'react';

import Head from 'next/head';

import {
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components';
import { ChakraProvider } from '@chakra-ui/react';

import pagLeeSocialMediaLogo from '~/shared/assets/images/pag-lee-social-media-logo.png';
import pagLeeLogoImg from '~/shared/assets/images/pag-lee-logo.png';
import pagLeeFavicon from '~/shared/assets/images/pag-lee-favicon.ico';

import { FCWithChildren } from '~/shared/types/FCWithChildren';
import { IResaleTheme } from '~/shared/interfaces/IResaleTheme';

import { theme as baseTheme } from '~/shared/styles/theme';

interface IThemeContextData
  extends DefaultTheme,
    Omit<IResaleTheme, 'colors'> {}

const ThemeContext = createContext({} as IThemeContextData);

interface IThemeProviderProps {
  theme: IResaleTheme;
}

const ThemeProvider: FCWithChildren<IThemeProviderProps> = ({
  children,
  theme = {} as IResaleTheme,
}) => {
  const formattedTheme = useMemo(
    () => ({
      ...baseTheme,
      colors: { ...baseTheme.colors, ...(theme.colors || {}) },
    }),
    [theme?.colors]
  );

  const contextData: IThemeContextData = useMemo(
    () => ({
      ...theme,
      logo: theme.logo || pagLeeLogoImg.src,
      favicon: theme.favicon || pagLeeFavicon.src,
      title: theme.title || 'Portal do Cliente | PagLee',
      mediaLogo: theme.socialMediaLogo || pagLeeSocialMediaLogo.src,
      description:
        theme.description || 'Criada pela RS Solutions com o propósito de...',
      ...formattedTheme,
    }),
    [formattedTheme, theme]
  );

  return (
    <ThemeContext.Provider value={contextData}>
      <ChakraProvider resetCSS={false}>
        <StyledComponentsThemeProvider theme={formattedTheme}>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
            />
            <meta name="theme-color" content={contextData.colors.primary} />
            <meta name="description" content={contextData.description} />
            <meta property="og:image" content={contextData.socialMediaLogo} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="620" />

            <link rel="icon" type="image/x-icon" href={contextData.favicon} />

            <link rel="icon" type="image/x-icon" href={contextData.favicon} />

            <title>{contextData.title}</title>
          </Head>

          {children}
        </StyledComponentsThemeProvider>
      </ChakraProvider>
    </ThemeContext.Provider>
  );
};

const useTheme = (): IThemeContextData => {
  return useContext(ThemeContext);
};

export { ThemeProvider, useTheme };
