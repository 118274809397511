import { FC, MouseEventHandler, ReactNode, useMemo } from 'react';

import { IconType } from 'react-icons';

import { useRouter } from 'next/router';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

import { Badge } from '~/shared/components/Badge';

import { Container } from './styles';

export interface INavItemProps extends Omit<NextLinkProps, 'href' | 'onClick'> {
  icon?: IconType;
  label: string;
  shouldMatchExactHref?: boolean;
  permissions?: boolean[];
  comingSoon?: boolean;
  isSubMenu?: boolean;
  href?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  endContent?: ReactNode;
  menu?: INavItemProps[];
  iconSize?: number;
  iconStrokeWidth?: number;
  forceActive?: boolean;
}

export const NavItem: FC<INavItemProps> = ({
  icon: Icon,
  endContent,
  href,
  label,
  shouldMatchExactHref = false,
  comingSoon = false,
  isSubMenu = false,
  onClick = () => null,
  iconSize = 16,
  iconStrokeWidth = 2.5,
  forceActive = false,
  ...props
}) => {
  const { asPath } = useRouter();

  const isActive = useMemo(() => {
    if (forceActive) return forceActive;

    if (shouldMatchExactHref && asPath === href) {
      return true;
    }

    if (!shouldMatchExactHref && asPath.startsWith(String(href))) {
      return true;
    }

    return false;
  }, [asPath, href, shouldMatchExactHref, forceActive]);

  const ItemContent = useMemo(() => {
    return (
      <>
        {!!Icon && <Icon />}

        <span>{label}</span>

        {endContent || (comingSoon && <Badge type="darkGray">Em breve</Badge>)}
      </>
    );
  }, [Icon, label, endContent, comingSoon]);

  return (
    <Container
      isActive={isActive}
      isDisabled={comingSoon}
      isSubMenu={isSubMenu}
      iconSize={iconSize}
      iconStrokeWidth={iconStrokeWidth}
    >
      {href ? (
        <NextLink href={href} passHref {...props}>
          {ItemContent}
        </NextLink>
      ) : (
        <button type="button" onClick={onClick}>
          {ItemContent}
        </button>
      )}
    </Container>
  );
};
