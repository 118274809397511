import { FiChevronDown } from 'react-icons/fi';

import styled, { css } from 'styled-components';

export interface ICollapseArrowProps {
  readonly open: boolean;
  readonly size?: number;
}

export const CollapseArrow = styled(FiChevronDown)<ICollapseArrowProps>`
  min-height: ${({ size }) => `${size || 20}px`};
  max-height: ${({ size }) => `${size || 20}px`};
  min-width: ${({ size }) => `${size || 20}px`};
  max-width: ${({ size }) => `${size || 20}px`};

  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `};
`;
