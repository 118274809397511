import {
  createContext,
  useContext,
  useState,
  useCallback,
  useInsertionEffect,
  useMemo,
} from 'react';

import { useCookies } from 'react-cookie';

import { useRouter } from 'next/router';

import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { AxiosError } from 'axios';

import { routes } from '~/shared/constants/routes';
import { cookiesConstants } from '~/shared/constants/cookies';

import { useToast } from '~/shared/hooks/useToast';
import { useDomain } from '~/shared/hooks/useDomain';

import { setApiDefaults } from '~/shared/services/api';
import {
  signIn as apiSignIn,
  refreshToken as apiRefreshToken,
  signUp as apiSignUp,
} from '~/modules/auth/services/auth';

import { TimeZoneEnum } from '~/shared/enums/TimeZoneEnum';

import { FCWithChildren } from '~/shared/types/FCWithChildren';
import { ICompany } from '~/modules/companies/interfaces/ICompany';
import { ISignUpCredentials } from '../interfaces/ISignUpCredentials';
import { ISignInCredentials } from '../interfaces/ISignInCredentials';
import { IUser } from '../../users/interfaces/IUser';

interface IAuthContextData {
  user: IUser;
  selectedCompany: ICompany;
  signIn(credentials: ISignInCredentials): Promise<void>;
  signUp(credentials: ISignUpCredentials): Promise<void>;
  signOut(): Promise<void>;
  refreshAccessToken(): Promise<void>;
  selectCompany(
    company: ICompany,
    alreadyIsAuthenticated?: boolean
  ): Promise<void>;
  updateUser(user: IUser): void;
  isRefreshingAccessToken: boolean;
  timeZone: TimeZoneEnum;
}

const AuthContext = createContext({} as IAuthContextData);

const AuthProvider: FCWithChildren = ({ children }) => {
  const router = useRouter();
  const toast = useToast();
  const { domain } = useDomain();

  const [cookies] = useCookies([
    cookiesConstants.SELECTED_COMPANY_ID,
    cookiesConstants.SELECTED_COMPANY_NAME,
    cookiesConstants.USER_TIME_ZONE,
  ]);

  const defaultSelectedCompany = cookies?.[
    cookiesConstants.SELECTED_COMPANY_NAME
  ]
    ? ({
        _id: cookies[cookiesConstants.SELECTED_COMPANY_ID],
        name: cookies[cookiesConstants.SELECTED_COMPANY_NAME],
      } as ICompany)
    : null;

  const [timeZone, setTimeZone] = useState(
    cookies[cookiesConstants.USER_TIME_ZONE] ||
      TimeZoneEnum['America/Sao_Paulo']
  );
  const [user, setUser] = useState<IUser | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(
    defaultSelectedCompany
  );
  const [isRefreshingAccessToken, setIsRefrehsingAccessToken] = useState(true);

  const selectCompany = useCallback(
    async (company: ICompany, alreadyIsAuthenticated = false) => {
      if (!company) return;

      setSelectedCompany(company);
      setCookie(null, cookiesConstants.SELECTED_COMPANY_ID, company._id, {
        path: '/',
      });
      setCookie(null, cookiesConstants.SELECTED_COMPANY_NAME, company.name, {
        path: '/',
      });

      if (alreadyIsAuthenticated) {
        window.location.href = routes.DASHBOARD;
      }
    },
    []
  );

  const updateUser = useCallback((newUser: IUser) => {
    if (newUser.location?.timeZone) {
      setTimeZone(newUser.location?.timeZone);
    }

    setUser(newUser);
  }, []);

  const signOut = useCallback(async () => {
    setApiDefaults(selectedCompany?._id, domain);

    await router.push(routes.AUTH.SIGN_IN);

    setUser(null);
    setSelectedCompany(null);

    destroyCookie(null, cookiesConstants.AUTH_ACCESS_TOKEN, { path: '/' });
    destroyCookie(null, cookiesConstants.AUTH_REFRESH_TOKEN, { path: '/' });
    destroyCookie(null, cookiesConstants.SELECTED_COMPANY_ID, { path: '/' });
    destroyCookie(null, cookiesConstants.SELECTED_COMPANY_NAME, { path: '/' });
    destroyCookie(null, cookiesConstants.USER_TIME_ZONE, { path: '/' });
  }, [domain, router, selectedCompany?._id]);

  const signIn = useCallback(
    async (credentials: ISignInCredentials) => {
      try {
        const apiResponse = await apiSignIn(credentials);

        const apiUser = apiResponse.user;

        const { [cookiesConstants.SELECTED_COMPANY_ID]: selectedCompanyId } =
          parseCookies(null);

        const findSelectedCompany = (apiUser.companies || []).find(
          (company) => company._id === selectedCompanyId
        );

        const newSelectedCompany =
          findSelectedCompany || apiUser.companies?.[0];

        if (apiUser?.location?.timeZone) {
          setTimeZone(apiUser.location?.timeZone);
        }

        setUser(apiUser);
        setApiDefaults(
          newSelectedCompany?._id,
          domain,
          apiResponse.accessToken,
          apiResponse.refreshToken,
          apiUser.location?.timeZone
        );

        await selectCompany(newSelectedCompany);

        await router.push(routes.DASHBOARD);
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 401) {
          toast.show({
            title: 'Ops, credenciais inválida(s)!',
            description: 'Seu e-mail e/ou senha está(ão) incorreto(s).',
            variant: 'error',
          });
          return;
        }

        toast.show({
          title: 'Ops, não foi possível fazer o login!',
          description: 'Recarregue a página e tente novamente.',
          variant: 'error',
        });
      }
    },
    [domain, router, selectCompany, toast]
  );

  const signUp = useCallback(
    async (credentials: ISignUpCredentials) => {
      try {
        await apiSignUp(credentials);

        await signIn({
          email: credentials.email,
          password: credentials.password,
        });

        toast.show({
          title: 'Boa, sua conta foi criada com sucesso!',
          description:
            'Agora, preencha os campos abaixos para configurar a sua empresa.',
          variant: 'success',
        });
      } catch (error) {
        toast.show({
          title: 'Ops, ocorreu um erro ao criar o sua conta!',
          description:
            error?.response?.data?.displayMessage ||
            error?.response?.data?.message ||
            'Recarregue a página e tente novamente.',
          variant: 'error',
        });
      }
    },
    [signIn, toast]
  );

  const refreshAccessToken = useCallback(async () => {
    const { [cookiesConstants.AUTH_REFRESH_TOKEN]: refreshToken } =
      parseCookies();

    if (!refreshToken) {
      setIsRefrehsingAccessToken(false);
      return;
    }

    try {
      const refreshTokenResponse = await apiRefreshToken(refreshToken);

      if (refreshTokenResponse) {
        const { [cookiesConstants.SELECTED_COMPANY_ID]: selectedCompanyId } =
          parseCookies(null);

        const apiUser = refreshTokenResponse.user;

        const findSelectedCompany = (apiUser.companies || []).find(
          (company) => company._id === selectedCompanyId
        );
        const newSelectedCompany =
          findSelectedCompany || apiUser.companies?.[0];

        await selectCompany(newSelectedCompany);

        setUser(apiUser);

        if (apiUser.location?.timeZone) {
          setTimeZone(apiUser.location?.timeZone);
        }

        setApiDefaults(
          newSelectedCompany?._id,
          domain,
          refreshTokenResponse.accessToken,
          refreshTokenResponse.refreshToken,
          apiUser.location?.timeZone
        );
      } else {
        await signOut();
      }
    } catch (error) {
      await signOut();
    } finally {
      setIsRefrehsingAccessToken(false);
    }
  }, [selectCompany, signOut, domain]);

  useInsertionEffect(() => {
    refreshAccessToken();
  }, []);

  const contextData: IAuthContextData = useMemo(
    () => ({
      user,
      signIn,
      signOut,
      selectedCompany,
      selectCompany,
      updateUser,
      signUp,
      refreshAccessToken,
      isRefreshingAccessToken,
      timeZone,
    }),
    [
      user,
      signIn,
      signOut,
      selectedCompany,
      selectCompany,
      updateUser,
      signUp,
      refreshAccessToken,
      isRefreshingAccessToken,
      timeZone,
    ]
  );

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};

const useAuth = (): IAuthContextData => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
