import queryString from 'qs';

import { IPaymentsFilters } from '~/modules/payments/interfaces/IPaymentsFilters';

export const routes = {
  AUTH: {
    SIGN_IN: '/',
    SIGN_UP: '/signup',
    PASSWORD_RECOVERY: '/password-recovery',
  },
  DASHBOARD: '/dashboard',
  PAYMENTS: {
    LIST: (filters = {} as IPaymentsFilters): string =>
      `/payments${
        Object.values(filters).length > 0
          ? `?${queryString.stringify(filters)}`
          : ''
      }`,
    DETAILS: (paymentId: string): string => `/payments/${paymentId}`,
  },
  PAYMENT_LINKS: {
    LIST: '/payment-links',
    CREATE: '/payment-links/create',
    UPDATE: (paymentLinkId: string): string =>
      `/payment-links/${paymentLinkId}`,
  },
  COMPANIES: {
    LIST: '/companies',
    CREATE: '/companies/create',
    UPDATE: (companyId: string): string => `/companies/${companyId}`,
  },
  PAYMENT_METHODS: '/payment-methods',
  CUSTOMERS: {
    LIST: '/customers',
    CREATE: '/customers/create',
    UPDATE: (customerId: string): string => `/customers/${customerId}`,
  },
  PROFILE: '/profile',
  INVOICES: {
    LIST: '/invoices',
    CREATE: '/invoices/create',
    DETAILS: (invoiceId: string): string => `/invoices/${invoiceId}`,
  },
  DOCUMENTS: {
    LIST: '/documents',
  },
};

export const authRoutes = Object.values(routes.AUTH);
