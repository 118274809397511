import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  align-items: center;

  width: 100%;

  background: ${({ theme }) => theme.colors.surface};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};

  > div {
    padding: ${({ theme }) => `0 ${theme.spacing.md}`};
    height: 68px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    display: flex;
    align-items: center;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;

  flex: 1;
  overflow: hidden;
  height: 100%;

  gap: ${({ theme }) => theme.spacing.md};

  > a {
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      object-fit: contain;
      object-position: left;
      max-width: 110px;
      width: max-content;
    }
  }
`;

export const ActionsWrapper = styled.ul`
  display: flex;
  align-items: center;

  gap: ${({ theme }) => theme.spacing.md};

  > li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
