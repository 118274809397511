import { forwardRef, ForwardRefRenderFunction } from 'react';

import { FiX } from 'react-icons/fi';

import Image from 'next/image';

import packageInfo from '~/../package.json';
import { Tooltip } from '@chakra-ui/react';

import { useTheme } from '~/shared/hooks/useTheme';

import { SideBarContainer, LogoContainer } from './styles';

import { SideBarNav } from './SideBarNav';
import { ToolbarIconButton } from '../../ToolbarIconButton';

interface ISideBarProps {
  onClose?(): void;
}

const BaseSideBar: ForwardRefRenderFunction<HTMLElement, ISideBarProps> = (
  { onClose = () => null },
  ref
) => {
  const { logo } = useTheme();

  return (
    <SideBarContainer ref={ref}>
      <header>
        <LogoContainer>
          <Image height={40} width={110} alt="Logo" src={logo} />
        </LogoContainer>

        <ToolbarIconButton icon={FiX} tooltip="Fechar" onClick={onClose} />
      </header>

      <SideBarNav />

      <footer>
        {/* @ts-ignore */}
        <Tooltip hasArrow label="Versão do sistema" closeOnClick={false}>
          <span>{`v${packageInfo.version}`}</span>
        </Tooltip>
      </footer>
    </SideBarContainer>
  );
};

export const SideBar = forwardRef(BaseSideBar);
