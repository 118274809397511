import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import {
  IMarginProps,
  marginPropsStyles,
} from '~/shared/interfaces/IMarginProps';

interface IOptionProps {
  isActive?: boolean;
  isPlaceholder?: boolean;
}

export interface IDropdownContainerProps {
  isVisible: boolean;
  selectClientRect: DOMRect;
}

interface IDropdownProps {
  isVisible: boolean;
  selectClientRect: DOMRect;
}

export const Container = styled.label<IMarginProps>`
  position: relative;

  ${marginPropsStyles}
`;

export const InputWrapper = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  height: 36px;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background: ${({ theme }) => theme.colors.gray[50]};
  border: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};
  cursor: pointer;

  transition: all ease-in 0.2s;

  > button {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;

    padding: ${({ theme }) => `0 ${theme.spacing.sm}`};
    gap: 12px;
  }
`;

export const Value = styled.input<{ value: string }>`
  font-size: 12px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.onSurface};
  align-items: center;
  z-index: 10;
  background: transparent;
  cursor: pointer;

  opacity: 1;

  &:after {
    content: ${({ value }) => value || ''};
  }

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 100%;
  margin-left: auto;

  > svg {
    width: 12px;
    height: 12px;
    color: ${({ theme }) => theme.colors.onSurface};
    margin: -2.2px -2px;
    stroke-width: 2.2px;
  }
`;

export const DropdownContainer = styled.div<IDropdownContainerProps>`
  position: absolute;
  height: ${({ selectClientRect }) => selectClientRect?.height || 0}px;
  width: ${({ selectClientRect }) => selectClientRect?.width || 0}px;
  left: ${({ selectClientRect }) => selectClientRect?.left || 0}px;
  top: ${({ selectClientRect }) => selectClientRect?.top || 0}px;

  > div {
    height: 100%;
    width: 100%;
    position: relative;
  }

  transition: visibility ease-in 0.2s;

  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

export const Dropdown = styled.ul<IDropdownProps>`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.surface};

  top: 100%;

  width: 100%;
  left: 0;
  border: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};
  box-shadow: ${({ theme }) => theme.boxShadow.sm};
  overflow-x: auto;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  z-index: 1000;

  transition: visibility ease-in 0.2s, opacity ease-in 0.2s,
    transform ease-in 0.2s;

  transform: translateY(-6px);
  opacity: 0;
  visibility: hidden;

  max-height: 200px;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translateY(6px);
    `}
`;

export const Option = styled.li<IOptionProps>`
  display: flex;
  align-items: center;

  cursor: pointer;
  font-size: 12px;
  line-height: 20px;
  min-height: 36px;
  padding: ${({ theme }) => `${theme.spacing.xs} 12px`};

  background-color: ${({ theme }) => theme.colors.surface};
  color: ${({ theme }) => theme.colors.onSurface};

  transition: background-color ease-in 0.3s, filter ease-in 0.2s,
    color ease-in 0.2s;

  &:hover {
    filter: brightness(0.98);
  }

  & + & {
    border-top: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      font-weight: 600;
      background: ${transparentize(0.96, theme.colors.primary)};
      color: ${theme.colors.primary};
    `}

  ${({ isPlaceholder, theme }) =>
    isPlaceholder &&
    css`
      font-weight: 600;
      color: ${theme.colors.gray[200]};
    `}
`;
