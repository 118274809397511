/* eslint-disable react/no-array-index-key */
import { FC, Fragment, useEffect, useState } from 'react';

import { FiCornerDownRight } from 'react-icons/fi';

import { useRouter } from 'next/router';

import { CollapseArrow } from '~/shared/components/Collapse/CollapseArrow';
import { Collapse } from '~/shared/components/Collapse';

import { Container } from './styles';

import { NavItem, INavItemProps } from '../NavItem';

export const NavMenu: FC<INavItemProps> = ({
  icon,
  label,
  comingSoon,
  menu,
}) => {
  const router = useRouter();

  const [open, setOpen] = useState<boolean>(false);

  const toggle = (): void => {
    if (!comingSoon) {
      setOpen((prevState) => !prevState);
    }
  };

  useEffect(() => {
    const hasSomeActiveItem = (menu || []).some((item) => {
      if (item.shouldMatchExactHref && router.asPath === item.href) {
        return true;
      }

      if (
        !item.shouldMatchExactHref &&
        router.asPath.startsWith(String(item.href))
      ) {
        return true;
      }

      return false;
    });

    if (hasSomeActiveItem) setOpen(true);
  }, [menu, router.asPath]);

  return (
    <Container isDisabled={comingSoon}>
      <NavItem
        icon={icon}
        comingSoon={comingSoon}
        label={label}
        onClick={toggle}
        endContent={<CollapseArrow open={open} />}
      />

      <Collapse isOpen={open}>
        {menu.map((item) => (
          <Fragment key={`${String(item.href)}:${item.label}`}>
            {((item.permissions || []).length === 0 ||
              item.permissions.some((p) => !!p)) && (
              <NavItem icon={FiCornerDownRight} {...item} />
            )}
          </Fragment>
        ))}
      </Collapse>
    </Container>
  );
};
