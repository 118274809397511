/* eslint-disable import/no-cycle */
import Router from 'next/router';

import { destroyCookie, parseCookies, setCookie } from 'nookies';
import axios, { AxiosError, AxiosInstance } from 'axios';

import { AuthTokenError } from '~/shared/errors/AuthTokenError';

import { routes } from '~/shared/constants/routes';
import { envs } from '~/shared/constants/envs';
import { cookiesConstants } from '~/shared/constants/cookies';

import { TimeZoneEnum } from '~/shared/enums/TimeZoneEnum';

export function setupApi(ctx = undefined): AxiosInstance {
  const parsedCookies = parseCookies(ctx);

  const api = axios.create({
    baseURL: envs.API_URL,
    headers: {
      ...(parsedCookies?.[cookiesConstants.AUTH_ACCESS_TOKEN] && {
        Authorization: `Bearer ${
          parsedCookies[cookiesConstants.AUTH_ACCESS_TOKEN]
        }`,
      }),
      ...(!!parsedCookies?.[cookiesConstants.SELECTED_COMPANY_ID] && {
        'x-company-id': parsedCookies[cookiesConstants.SELECTED_COMPANY_ID],
      }),
    },
  });

  api.interceptors.response.use(
    (success) => success,
    (error: AxiosError<any>) => {
      if (error.response?.status === 401) {
        if (error.response?.data?.code === 'token_expired') {
          if (typeof window !== 'undefined') {
            destroyCookie(null, cookiesConstants.AUTH_ACCESS_TOKEN, {
              path: '/',
            });
            destroyCookie(null, cookiesConstants.AUTH_REFRESH_TOKEN, {
              path: '/',
            });
            destroyCookie(null, cookiesConstants.SELECTED_COMPANY_ID, {
              path: '/',
            });
            destroyCookie(null, cookiesConstants.SELECTED_COMPANY_NAME, {
              path: '/',
            });
            destroyCookie(null, cookiesConstants.USER_TIME_ZONE, { path: '/' });
            api.defaults.headers['Authorization'] = '';
            Router.push(routes.AUTH.SIGN_IN);
          } else {
            return Promise.reject(new AuthTokenError());
          }
        }
      }

      return Promise.reject(error);
    }
  );

  return api;
}

export const api = setupApi();

export const setApiDefaults = (
  selectedCompanyId: string,
  domain: string,
  accessToken?: string,
  refreshToken?: string,
  timeZone?: TimeZoneEnum
): void => {
  if (!api) return;

  if (!accessToken || !refreshToken) {
    destroyCookie(null, cookiesConstants.AUTH_ACCESS_TOKEN, { path: '/' });
    destroyCookie(null, cookiesConstants.AUTH_REFRESH_TOKEN, { path: '/' });
    destroyCookie(null, cookiesConstants.SELECTED_COMPANY_ID, { path: '/' });
    destroyCookie(null, cookiesConstants.SELECTED_COMPANY_NAME, { path: '/' });
    destroyCookie(null, cookiesConstants.USER_TIME_ZONE, { path: '/' });
    api.defaults.headers['Authorization'] = '';
    return;
  }

  api.defaults.headers['Authorization'] = `Bearer ${accessToken}`;

  if (selectedCompanyId) {
    api.defaults.headers['x-company-id'] = selectedCompanyId;
  }

  setCookie(null, cookiesConstants.AUTH_ACCESS_TOKEN, accessToken, {
    path: '/',
  });
  setCookie(null, cookiesConstants.AUTH_REFRESH_TOKEN, refreshToken, {
    path: '/',
  });

  if (timeZone) {
    setCookie(null, cookiesConstants.USER_TIME_ZONE, timeZone, { path: '/' });
  }
};
