import { FC } from 'react';

import NextNProgress from 'nextjs-progressbar';

import { useTheme } from '~/shared/hooks/useTheme';

export const TopBar: FC = () => {
  const theme = useTheme();

  return (
    <NextNProgress
      color={theme.colors.primary}
      height={3}
      showOnShallow
      options={{
        showSpinner: false,
        speed: 500,
      }}
    />
  );
};
