import styled, { css } from 'styled-components';

export type BadgeType =
  | 'warning'
  | 'danger'
  | 'success'
  | 'info'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'darkGray';

interface IContainerProps {
  type: BadgeType;
  isDisabled: boolean;
  href: string;
}

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div<IContainerProps>`
  display: inline-block;
  padding: 0 10px;

  background: ${({ theme, type }) => theme.colors[type]}33;

  color: ${({ theme, type }) => theme.colors[type]};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  line-height: 0px;
  font-size: 9px;
  height: 22px;
  overflow: hidden;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  cursor: default;

  transition: all 0.3s;

  ${({ onClick, type, theme, href }) =>
    (!!onClick || !!href) &&
    css`
      cursor: pointer;

      &:hover {
        background: ${theme.colors[type]}55;
      }
    `}

  ${({ theme, type, isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      opacity: 0.65;

      &:hover {
        background: ${theme.colors[type]}33;
      }

      pointer-events: none;
    `}

  > div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    gap: ${({ theme }) => theme.spacing.xs};

    svg {
      width: 9px;
      height: 9px;
    }
  }
`;
