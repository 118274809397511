import styled, { css } from 'styled-components';

interface IContainerProps {
  size: number;
  iconSize: number;
  isRounded: boolean;
  transparentWithPicture: boolean;
  hasPicture: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: ${({ size }) => size}px !important;
  min-height: ${({ size }) => size}px !important;
  border-radius: ${({ isRounded }) => (isRounded ? '50%' : 'none')};

  background-color: ${({ theme }) => theme.colors.gray[50]};
  border: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};

  overflow: hidden;

  > svg {
    width: ${({ iconSize }) => iconSize}px;
    height: ${({ iconSize }) => iconSize}px;
    color: ${({ theme }) => theme.colors.onSurface};
  }

  ${({ hasPicture, transparentWithPicture }) =>
    hasPicture &&
    transparentWithPicture &&
    css`
      background-color: transparent;
      border: none;
    `}
`;
