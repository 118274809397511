import styled, { css } from 'styled-components';

interface ContainerProps {
  isDisabled: boolean;
  isSubMenu?: boolean;
}

export const Container = styled.li<ContainerProps>`
  list-style: none;
  width: 100%;

  ${({ isSubMenu }) =>
    isSubMenu &&
    css`
      padding-left: ${({ theme }) => theme.spacing.md};
    `}
`;

interface IMenuWrapper {
  readonly open: boolean;
  readonly height: number;
}

export const MenuWrapper = styled.div<IMenuWrapper>`
  width: 100%;
  height: max-content;
`;
