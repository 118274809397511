import { createContext, useContext, useMemo } from 'react';

import { FCWithChildren } from '../types/FCWithChildren';

interface IDomainContextData {
  domain: string;
}

interface IDomainProviderProps {
  defaultDomain: string;
}

const DomainContext = createContext({} as IDomainContextData);

const DomainProvider: FCWithChildren<IDomainProviderProps> = ({
  children,
  defaultDomain,
}) => {
  const contextData: IDomainContextData = useMemo(
    () => ({ domain: defaultDomain }),
    [defaultDomain]
  );

  return (
    <DomainContext.Provider value={contextData}>
      {children}
    </DomainContext.Provider>
  );
};

const useDomain = (): IDomainContextData => {
  return useContext(DomainContext);
};

export { DomainProvider, useDomain };
