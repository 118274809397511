import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

interface IDropdownItemProps {
  isActive?: boolean;
}

interface IIconWrapperProps {
  isDropdownVisible: boolean;
}

export interface IDropdownContainerProps {
  isVisible: boolean;
  selectClientRect: DOMRect;
}

interface IDropdownProps {
  isVisible: boolean;
  selectClientRect: DOMRect;
}

interface ILabelWrapperProps {
  isActive: boolean;
  isDropdownVisible: boolean;
}

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacing.sm}`};
`;

export const LabelWrapper = styled.div<ILabelWrapperProps>`
  color: ${({ theme }) => theme.colors.gray[300]};
  position: relative;
  font-size: 14px;

  transition: color 0.3s ease-in;

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xxs};

  white-space: nowrap;
  cursor: pointer;

  &::after {
    content: '';
    width: 0;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.primary};
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);

    transition: width 0.2s ease-out;
  }

  ${({ theme, isActive }) =>
    isActive &&
    css`
      color: ${theme.colors.primary};
      pointer-events: none;
    `}

  ${({ theme, isDropdownVisible }) =>
    isDropdownVisible &&
    css`
      color: ${theme.colors.primary};

      &::after {
        width: 100%;
      }
    `}
`;

export const IconWrapper = styled.div<IIconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  transition: transform ease-in 0.3s;

  ${({ isDropdownVisible }) =>
    isDropdownVisible &&
    css`
      transform: rotate(180deg);
    `}

  > svg {
    width: 16px;
    height: 16px;
  }
`;

export const DropdownContainer = styled.div<IDropdownContainerProps>`
  position: absolute;
  width: 160px;
  left: ${({ selectClientRect }) => selectClientRect?.left || 0}px;
  top: ${({ selectClientRect }) => selectClientRect?.bottom || 0}px;

  > div {
    height: 100%;
    width: 100%;
    position: relative;
  }

  transition: visibility ease-in 0.2s;

  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

export const Dropdown = styled.ul<IDropdownProps>`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.surface};

  width: 100%;
  top: 5px;
  left: 0;
  border: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};
  box-shadow: ${({ theme }) => theme.boxShadow.sm};
  overflow-x: auto;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  z-index: 1000;

  transition: visibility ease-in 0.2s, opacity ease-in 0.2s,
    transform ease-in 0.2s;

  transform: translateY(-6px);
  opacity: 0;
  visibility: hidden;

  max-height: 200px;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translateY(6px);
    `}
`;

export const DropdownItem = styled.li<IDropdownItemProps>`
  display: flex;
  align-items: center;

  cursor: pointer;

  background-color: ${({ theme }) => theme.colors.surface};

  transition: background-color ease-in 0.3s, filter ease-in 0.2s,
    color ease-in 0.2s;

  &:hover {
    filter: brightness(0.98);
  }

  & + & {
    border-top: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};
  }

  > a {
    padding: ${({ theme }) => `${theme.spacing.xs} 12px`};
    color: ${({ theme }) => theme.colors.onSurface};
    font-size: 12px;
    line-height: 20px;
    min-height: 36px;
    width: 100%;
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background: ${transparentize(0.96, theme.colors.primary)};

      > a {
        font-weight: 600;
        color: ${theme.colors.primary};
      }
    `}
`;
