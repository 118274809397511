import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100vw;

  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  overflow: hidden;

  main {
    display: flex;
    flex-direction: column;

    max-height: 100%;
    height: 100%;
    width: 100%;
    background: ${({ theme }) => theme.colors.background};

    overflow: auto;
  }
`;
