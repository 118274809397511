import styled, { css } from 'styled-components';

interface IContainerProps {
  isActive: boolean;
}

export const Container = styled.div<IContainerProps>`
  list-style: none;

  padding: ${({ theme }) => `0 ${theme.spacing.sm}`};

  font-size: 14px;

  > a {
    height: 100%;
    color: ${({ theme }) => theme.colors.gray[300]};
    position: relative;

    display: flex;
    align-items: center;

    transition: color 0.3s ease-in;

    white-space: nowrap;

    &::after {
      content: '';
      width: 0;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.primary};
      position: absolute;
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%);

      transition: width 0.2s ease-out;
    }

    ${({ theme, isActive }) =>
      isActive
        ? css`
            color: ${theme.colors.primary};
            pointer-events: none;
          `
        : css`
            &:hover {
              color: ${theme.colors.primary};

              &::after {
                width: 100%;
              }
            }
          `}
  }
`;
