import { FC, memo, useMemo } from 'react';

import { useRouter } from 'next/router';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

import isValuesEqual from 'lodash/isEqual';

import { Container } from './styles';

export interface INavItemProps extends NextLinkProps {
  label: string;
  shouldMatchExactHref?: boolean;
}

const BaseNavItem: FC<INavItemProps> = ({
  href,
  label,
  shouldMatchExactHref = false,
  ...props
}) => {
  const { asPath } = useRouter();

  const isActive = useMemo(() => {
    if (shouldMatchExactHref && asPath === href) {
      return true;
    }

    if (!shouldMatchExactHref && asPath.startsWith(String(href))) {
      return true;
    }

    return false;
  }, [asPath, href, shouldMatchExactHref]);

  return (
    <Container isActive={isActive}>
      <NextLink href={href} {...props}>
        {label}
      </NextLink>
    </Container>
  );
};

export const NavItem = memo(BaseNavItem, isValuesEqual);
