import { formatSignUpRequest } from '~/modules/auth/utils/formatSignUpRequest';

import { api } from '~/shared/services/api';

import { ISignUpCredentials } from '../interfaces/ISignUpCredentials';
import { ISignInResponse } from '../interfaces/ISignInResponse';
import { ISignInCredentials } from '../interfaces/ISignInCredentials';
import { IRefreshTokenResponse } from '../interfaces/IRefreshTokenResponse';

export async function signUp(credentials: ISignUpCredentials): Promise<void> {
  const formattedRequest = formatSignUpRequest(credentials);

  await api.post<ISignInResponse>('/auth/signup', formattedRequest);
}

export async function signIn(
  credentials: ISignInCredentials
): Promise<ISignInResponse> {
  const encodedUserCredentials = Buffer.from(
    `${credentials.email}:${credentials.password}`
  ).toString('base64');

  const response = await api.post<ISignInResponse>(
    '/auth/signin',
    { grantType: 'user_credentials' },
    { headers: { Authorization: `Basic ${encodedUserCredentials}` } }
  );

  return response.data;
}

export async function refreshToken(
  _refreshToken: string
): Promise<IRefreshTokenResponse> {
  const response = await api.post<IRefreshTokenResponse>(
    '/auth/refresh-token',
    { refreshToken: _refreshToken }
  );

  return response.data;
}
