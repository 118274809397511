import { CookiesProvider } from 'react-cookie';

import { ThemeProvider } from '~/shared/hooks/useTheme';
import { SideBarProvider } from '~/shared/hooks/useSideBar';
import { ModalProvider } from '~/shared/hooks/useModal';
import { DomainProvider } from '~/shared/hooks/useDomain';
import { AuthProvider } from '~/modules/auth/hooks/useAuth';

import { FCWithChildren } from '~/shared/types/FCWithChildren';
import { IResale } from '~/shared/interfaces/IResale';

interface IAppProviderProsp {
  resale: IResale;
  domain: string;
}

export const AppProvider: FCWithChildren<IAppProviderProsp> = ({
  children,
  resale,
  domain,
}) => {
  return (
    <DomainProvider defaultDomain={domain}>
      <CookiesProvider>
        <ThemeProvider theme={resale?.theme}>
          <AuthProvider>
            <ModalProvider>
              <SideBarProvider>{children}</SideBarProvider>
            </ModalProvider>
          </AuthProvider>
        </ThemeProvider>
      </CookiesProvider>
    </DomainProvider>
  );
};
