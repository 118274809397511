import {
  CSSProperties,
  FC,
  FocusEventHandler,
  memo,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';

import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { createPortal } from 'react-dom';

import isValuesEqual from 'lodash/isEqual';

import { useIsMounted } from '~/shared/hooks/useIsMounted';
import { useEventListener } from '~/shared/hooks/useEventListener';
import { useClickOutside } from '~/shared/hooks/useClickOutside';
import { useClickEsc } from '~/shared/hooks/useClickEsc';

import { Tooltip } from '~/shared/components/Tooltip';

import { IMarginProps } from '~/shared/interfaces/IMarginProps';
import { ICompany } from '~/modules/companies/interfaces/ICompany';

import {
  Container,
  InputWrapper,
  Dropdown,
  Option,
  IconWrapper,
  Value,
  DropdownContainer,
} from './styles';

export type ICompanyOption = Pick<ICompany, '_id' | 'name'>;

export type TOnChangeCompanySelect = (option: ICompanyOption) => void;

export interface ICompaniesSelectProps extends IMarginProps {
  onChange?: TOnChangeCompanySelect;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  value?: ICompanyOption;
  options: ICompanyOption[];
  label?: string;
  scrollViewId?: string;
  containerStyle?: CSSProperties;
}

const BaseCompaniesSelect: FC<ICompaniesSelectProps> = ({
  onChange = () => null,
  value,
  options,
  m,
  ml,
  mr,
  mv,
  mh,
  mb,
  mt,
  scrollViewId = 'main-element',
  containerStyle = {},
}) => {
  const isMounted = useIsMounted();

  const id = useId();

  const dropdownRef = useRef<HTMLUListElement>(null);
  const containerRef = useRef<HTMLLabelElement>(null);

  const [clientRect, setClientRect] = useState<DOMRect>();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleSetClientRect = (): void => {
    const containerClientRect = containerRef.current?.getBoundingClientRect();

    setClientRect(containerClientRect);
  };

  const openDropdown = (): void => {
    if (isDropdownVisible) return;
    handleSetClientRect();
    setIsDropdownVisible(true);
  };

  const closeDropdown = (): void => {
    if (!isDropdownVisible) return;
    setIsDropdownVisible(false);
  };

  const handleClickSelect = (): void => {
    isDropdownVisible ? closeDropdown() : openDropdown();
  };

  const handleSelectOption = (option: ICompanyOption): void => {
    onChange(option);
    closeDropdown();
  };

  const verifyValueIsActive = useCallback(
    (valueToVerify: string): boolean => {
      return valueToVerify === value?._id;
    },
    [value]
  );

  const inputWrapperRef = useClickOutside<HTMLDivElement>(closeDropdown);

  useEffect(() => {
    handleSetClientRect();
  }, []);

  useClickEsc(closeDropdown);

  useEventListener('resize', handleSetClientRect);
  useEventListener(
    'scroll',
    closeDropdown,
    isMounted && document?.getElementById(scrollViewId)
  );

  return (
    <Container
      m={m}
      mv={mv}
      mh={mh}
      mt={mt}
      mb={mb}
      ml={ml}
      mr={mr}
      htmlFor={id}
      ref={containerRef}
    >
      <InputWrapper ref={inputWrapperRef} style={containerStyle}>
        <Tooltip label="Selecione uma empresa" isDisabled={isDropdownVisible}>
          <button type="button" onClick={handleClickSelect}>
            <Value value={value?.name} readOnly disabled />

            <IconWrapper onClick={handleClickSelect}>
              <FiChevronUp />
              <FiChevronDown />
            </IconWrapper>
          </button>
        </Tooltip>

        <>
          {isMounted &&
            options?.length > 0 &&
            createPortal(
              <DropdownContainer
                isVisible={isDropdownVisible}
                selectClientRect={clientRect}
              >
                <Dropdown
                  isVisible={isDropdownVisible}
                  ref={dropdownRef}
                  selectClientRect={clientRect}
                >
                  <Option
                    onClick={closeDropdown}
                    id="companies-select-option-placeholder"
                    isPlaceholder
                  >
                    Selecione uma empresa
                  </Option>

                  {options.map((option) => (
                    <Option
                      key={option._id}
                      onClick={() => handleSelectOption(option)}
                      id={`companies-select-option-${option._id}`}
                      {...(verifyValueIsActive(option._id) && {
                        isActive: true,
                      })}
                    >
                      {option.name}
                    </Option>
                  ))}
                </Dropdown>
              </DropdownContainer>,
              document.getElementById('portal-root') as Element
            )}
        </>
      </InputWrapper>
    </Container>
  );
};

export const CompaniesSelect = memo(BaseCompaniesSelect, isValuesEqual);
