import { FC, useEffect, useState } from 'react';

import { FiX } from 'react-icons/fi';

import { useRouter } from 'next/router';
import Link from 'next/link';

import { ScaleFade } from '@chakra-ui/react';

import { routes } from '~/shared/constants/routes';

import { useClickOutside } from '~/shared/hooks/useClickOutside';
import { useAuth } from '~/modules/auth/hooks/useAuth';

import { Tooltip } from '~/shared/components/Tooltip';
import { Avatar } from '~/shared/components/Avatar';

import { ICompany } from '~/modules/companies/interfaces/ICompany';

import {
  Container,
  AvatarButton,
  PopoverContainer,
  ClosePopoverButton,
  PopoverContent,
} from './styles';

import { CompaniesSelect } from './CompaniesSelect';

export const MyAccount: FC = () => {
  const router = useRouter();

  const {
    user,
    signOut,
    selectedCompany,
    selectCompany,
    isRefreshingAccessToken,
  } = useAuth();

  const [isOpen, setIsOpen] = useState(false);

  function closePopover(): void {
    setIsOpen(false);
  }

  useEffect(() => {
    router.events.on('routeChangeStart', closePopover);

    return () => {
      router.events.off('routeChangeStart', closePopover);
    };
  }, [router]);

  const popoverRef = useClickOutside<HTMLLIElement>(closePopover);

  useEffect(() => {
    document.addEventListener('keydown', closePopover, false);

    return () => {
      document.removeEventListener('keydown', closePopover, false);
    };
  }, []);

  function tooglePopperIsOpen(): void {
    setIsOpen((prevState) => !prevState);
  }

  return (
    <Container ref={popoverRef}>
      <AvatarButton type="button" onClick={tooglePopperIsOpen}>
        <Avatar alt={user?.name} tooltip="Minha conta" />
      </AvatarButton>

      <ScaleFade
        in={isOpen && !isRefreshingAccessToken}
        initialScale={0.9}
        unmountOnExit
      >
        <PopoverContainer>
          <Tooltip label="Fechar">
            <ClosePopoverButton type="button" onClick={closePopover}>
              <FiX />
            </ClosePopoverButton>
          </Tooltip>

          <PopoverContent>
            <div>
              <Avatar alt={user?.name} size={60} iconSize={20} />

              <strong>{user?.name}</strong>

              <span>{user?.email}</span>

              <CompaniesSelect
                mt="xs"
                options={user?.companies}
                value={selectedCompany}
                onChange={(company) => selectCompany(company as ICompany, true)}
              />
            </div>

            <Link href={routes.PROFILE}>Meu perfil</Link>

            <Link href={routes.COMPANIES.LIST}>Empresas</Link>

            <button type="button" onClick={signOut}>
              Sair do portal
            </button>
          </PopoverContent>
        </PopoverContainer>
      </ScaleFade>
    </Container>
  );
};
