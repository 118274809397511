import ErrorPage from 'next/error';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';

import { authRoutes } from '~/shared/constants/routes';

import { TopBar } from '~/shared/components/TopBar';
import { DefaultLayout } from '~/shared/components/DefaultLayout';
import { AuthLayout } from '~/shared/components/AuthLayout';
import { AppProvider } from '~/shared/components/AppProvider';

import { getDomainByContext } from '~/shared/utils/getDomainByContext';

import { loadResaleByDomain } from '~/shared/services/api/resales';
import { api } from '~/shared/services/api';

import { IResale } from '~/shared/interfaces/IResale';

import { GlobalStyle } from '~/shared/styles/global';

import '~/shared/utils/yupValidations';

interface IMyAppProps extends AppProps {
  resale?: IResale;
  domain?: string;
  errorCode?: string;
}

function MyApp({
  Component,
  pageProps,
  router,
  resale,
  domain,
  errorCode,
}: IMyAppProps): JSX.Element {
  if (errorCode === 'ECONNREFUSED') {
    return (
      <ErrorPage
        statusCode={503}
        title="Servidor temporariamente fora do ar"
        withDarkMode={false}
      />
    );
  }

  if (!domain || !resale) {
    return (
      <ErrorPage
        statusCode={404}
        title="Página não encontrada"
        withDarkMode={false}
      />
    );
  }

  const Layout = authRoutes.includes(router.asPath)
    ? AuthLayout
    : DefaultLayout;

  if (resale?._id) {
    api.defaults.headers['x-resale-id'] = resale._id;
  }

  return (
    <AppProvider resale={resale} domain={domain}>
      <GlobalStyle />

      <TopBar />

      <Layout>
        <Component {...pageProps} />
      </Layout>
    </AppProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  try {
    const domain = getDomainByContext(appContext.ctx);

    const resale = await loadResaleByDomain(domain);

    return {
      ...appProps,
      resale,
      domain,
    } as IMyAppProps;
  } catch (error) {
    return {
      ...appProps,
      errorCode: error.code,
    } as IMyAppProps;
  }
};

export default MyApp;
