import { FC } from 'react';

import Link from 'next/link';
import Image from 'next/image';

import { routes } from '~/shared/constants/routes';

import { useTheme } from '~/shared/hooks/useTheme';

import { Container, ActionsWrapper, LeftContent } from './styles';

import { SideBarDrawer } from './SideBarDrawer';
import { NavBar } from './NavBar';
import { MyAccount } from './MyAccount';

export const Toolbar: FC = () => {
  const { logo } = useTheme();

  return (
    <>
      <Container>
        <div>
          <LeftContent>
            <Link href={routes.DASHBOARD} passHref>
              <Image height={40} width={110} alt="Logo" src={logo} />
            </Link>

            <SideBarDrawer />

            <NavBar />
          </LeftContent>

          <ActionsWrapper>
            <MyAccount />
          </ActionsWrapper>
        </div>
      </Container>
    </>
  );
};
