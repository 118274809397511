import { FC } from 'react';

import { routes } from '~/shared/constants/routes';

import { Container } from './styles';

import { NavMenu } from './NavMenu';
import { NavItem } from './NavItem';

export const NavBar: FC = () => {
  return (
    <Container>
      <ul>
        <NavItem label="Resumo" href={routes.DASHBOARD} />

        <NavItem label="Transações" href={routes.PAYMENTS.LIST()} />

        <NavItem label="Clientes" href={routes.CUSTOMERS.LIST} />

        <NavItem label="Formas de pagamento" href={routes.PAYMENT_METHODS} />

        <NavMenu
          label="Serviços"
          items={[
            {
              label: 'Gestão de cobranças',
              href: routes.INVOICES.LIST,
            },
            {
              label: 'Links de pagamento',
              href: routes.PAYMENT_LINKS.LIST,
            },
            {
              label: 'DIRF/EFD-REINF',
              href: routes.DOCUMENTS.LIST,
            },
          ]}
        />
      </ul>
    </Container>
  );
};
