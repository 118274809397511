import { CSSProperties } from 'styled-components';
import { Collapse as ChakraCollapse } from '@chakra-ui/react';

import { FCWithChildren } from '~/shared/types/FCWithChildren';

interface ICollapseProps {
  isOpen?: boolean;
  contentStyle?: CSSProperties;
  startingHeight?: number;
}

export const Collapse: FCWithChildren<ICollapseProps> = ({
  isOpen = false,
  children,
  contentStyle,
  startingHeight,
}) => {
  return (
    <ChakraCollapse
      in={isOpen}
      animateOpacity
      style={contentStyle}
      startingHeight={startingHeight}
    >
      {children}
    </ChakraCollapse>
  );
};
