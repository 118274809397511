import { NextPageContext, GetServerSidePropsContext } from 'next';

export function getDomainByContext(
  ctx: NextPageContext | GetServerSidePropsContext
): string {
  const domain =
    (ctx.req?.headers?.['x-forwarded-host'] as string) ||
    ctx.req?.headers.host ||
    ctx.req?.headers.referer;
  if (!domain) return '';

  return domain.replace(/.*?:\/\//, '').replace(/\/$/, '');
}
