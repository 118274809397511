import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FiChevronDown } from 'react-icons/fi';
import { createPortal } from 'react-dom';

import { useRouter } from 'next/router';
import Link from 'next/link';

import isValuesEqual from 'lodash/isEqual';

import { withIsMounted } from '~/shared/utils/withIsMounted';

import {
  Container,
  Dropdown,
  IconWrapper,
  DropdownContainer,
  LabelWrapper,
  DropdownItem,
} from './styles';

import { INavItemProps } from '../NavItem';

export interface INavMenuProps {
  items: INavItemProps[];
  label?: string;
}

const BaseNavMenu: FC<INavMenuProps> = withIsMounted(({ items, label }) => {
  const router = useRouter();

  const dropdownRef = useRef<HTMLUListElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [clientRect, setClientRect] = useState<DOMRect>();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleSetClientRect = (): void => {
    const containerClientRect = contentRef.current?.getBoundingClientRect();
    setClientRect(containerClientRect);
  };

  const openDropdown = (): void => {
    if (isDropdownVisible) return;
    handleSetClientRect();
    setIsDropdownVisible(true);
  };

  const closeDropdown = (): void => {
    if (!isDropdownVisible) return;
    setIsDropdownVisible(false);
  };

  const verifyValueIsActive = useCallback(
    (item: INavItemProps): boolean => {
      if (item.shouldMatchExactHref && router.asPath === item.href) {
        return true;
      }

      if (
        !item.shouldMatchExactHref &&
        router.asPath.startsWith(String(item.href))
      ) {
        return true;
      }

      return false;
    },
    [router.asPath]
  );

  const isActive = useMemo(() => {
    return (items || []).some((item) => verifyValueIsActive(item));
  }, [items, verifyValueIsActive]);

  useEffect(() => {
    handleSetClientRect();
  }, []);

  return (
    <Container onMouseEnter={openDropdown} onMouseLeave={closeDropdown}>
      <div ref={contentRef}>
        <LabelWrapper isActive={isActive} isDropdownVisible={isDropdownVisible}>
          <span>{label}</span>

          <IconWrapper isDropdownVisible={isDropdownVisible}>
            <FiChevronDown />
          </IconWrapper>
        </LabelWrapper>

        <>
          {createPortal(
            <DropdownContainer
              isVisible={isDropdownVisible}
              selectClientRect={clientRect}
            >
              <Dropdown
                isVisible={isDropdownVisible}
                ref={dropdownRef}
                selectClientRect={clientRect}
              >
                {items?.length > 0 &&
                  items.map((item) => (
                    <DropdownItem
                      key={`${item.label}:${item.href}`}
                      id={`nav-menu-item-${item.label}:${item.href}`}
                      onClick={closeDropdown}
                      {...(verifyValueIsActive(item) && {
                        isActive: true,
                      })}
                    >
                      <Link href={item.href}>{item.label}</Link>
                    </DropdownItem>
                  ))}
              </Dropdown>
            </DropdownContainer>,
            document.getElementById('portal-root') as Element
          )}
        </>
      </div>
    </Container>
  );
});

export const NavMenu = memo(BaseNavMenu, isValuesEqual);
