import { unmask } from '~/shared/utils/masks';

import { ISignUpCredentials } from '../interfaces/ISignUpCredentials';

export function formatSignUpRequest(
  credentials: ISignUpCredentials
): ISignUpCredentials {
  const unmaskedDocument = unmask(credentials.document);
  const unmaskedPhoneNumber = unmask(credentials.phoneNumber);

  return {
    document: unmaskedDocument,
    phoneNumber: unmaskedPhoneNumber,
    email: credentials.email,
    name: credentials.name,
    password: credentials.password,
  };
}
