import styled from 'styled-components';

export const Container = styled.nav`
  height: 100%;

  > ul {
    display: flex;
    align-items: center;
    height: 100%;
  }

  @media (max-width: 860px) {
    display: none;
  }
`;
